import React, { useContext, useEffect, useState } from "react";
import { CartVector } from "../../../assets/svg/AllSvg";
import PackageDetails from "./PackageDetails";
import SelectDetails from "./SelectDetails";
import layoutContext from "../../../layouts/layoutContext";
import { useDispatch, useSelector } from "react-redux";
import { getPackageList } from "../../../redux/package/slice";
import RippleLoader from "../../../common/RippleLoader";
import Cookies from "js-cookie";

const SelectPackege = ({
  setSelectedPackages,
  selectedPackages,
  handleRemovePackage,
}) => {
  const [isnext, setIsNext] = useState(true);
  const { next } = useContext(layoutContext);
  const [counting, SetCounting] = useState([]);
  const dispatch = useDispatch();

  // selector
  const { packageList, packageListLoading } = useSelector((store) => ({
    packageList: store?.packageData?.getPackageListStatus?.data,
    packageListLoading: store?.packageData?.loading,
  }));

  useEffect(() => {
    setIsNext(selectedPackages.length === 0);
  }, [selectedPackages]);

  //Get Package List
  useEffect(() => {
    dispatch(
      getPackageList({
        pageCount: 1,
        pageLimit: 10,
        sort_by: "",
        sort_direction: "desc",
      })
    );
  }, [dispatch]);

  // Handle add package
  const handlePackageSelection = (pkg, count) => {
    const packages = selectedPackages?.find((p) => p.id === pkg.id);
    if (!packages) {
      const updatedPackages = [...selectedPackages, pkg];
      setSelectedPackages(updatedPackages);
      Cookies.set("selectedPackages", JSON.stringify(updatedPackages));
      setIsNext(false);
    }
  };

  return (
    <div className="grid sm:grid-cols-3 grid-rows items-start gap-2 my-3">
      <div className="bg-white rounded-2xl text-black py-5 px-6 sm:col-span-2">
        <div>
          <div className="flex items-center gap-3">
            <CartVector />
            <p className="text-brown md:text-2xl text-base font-normal">
              Yi Wei’s Packages
            </p>
          </div>
          <div className="mb-5">
            <p className="sm:text-sm text-xs font-normal">
              Find the right package to suit your needs and preferences.
            </p>
          </div>
          {packageListLoading ? (
            <RippleLoader />
          ) : (
            <div className="space-y-3 max-h-[500px] overflow-auto ">
              {(packageList?.data?.data || []).map((item, index) => (
                <div className="card">
                  <PackageDetails
                    setIsNext={setIsNext}
                    key={index}
                    pkg={item}
                    counting={counting}
                    SetCounting={SetCounting}
                    handlePackageSelection={handlePackageSelection}
                    handleRemovePackage={handleRemovePackage}
                    selectedPackages={selectedPackages}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <SelectDetails
        next={next}
        isStep={true}
        // setSaleId={setSaleId}
        isnext={isnext}
        selectedPackages={selectedPackages}
        handleRemovePackage={handleRemovePackage}
      />
    </div>
  );
};

export default SelectPackege;

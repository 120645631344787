import ReactSelect from "react-select";
import {
  AddCart,
  ArrowNext,
  Close,
  CloseVector,
} from "../../assets/svg/AllSvg";

export const Input = ({ className, ...res }) => {
  return <input className={className} {...res} />;
};
export const InputError = ({ errorTitle, classes }) => {
  return (
    <>
      <div
        className={`flex items-center text-maintheme sm:text-sm text-base mt-2 ${classes}`}
      >
        <p className="ml-1">{errorTitle}</p>
      </div>
    </>
  );
};
export const InputWithLabel = ({
  className,
  idFromName,
  label,
  type,
  errors,
  inputClass,
  errorsText,
  icon,
  ...res
}) => {
  return (
    <div>
      <div
        className={`${className} relative bg-white transition duration-300 rounded`}
      >
        <Input
          type={type}
          id={idFromName}
          name={idFromName}
          className={`block px-2.5 py-3 w-full border-secondary_grey text-base error-forms bg-transparent rounded border-1 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer ${inputClass}`}
          placeholder={" "}
          {...res}
        />
        <label
          htmlFor={idFromName}
          className="absolute text-primaryblack  pointer-events-none text-sm sm:text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-5 left-1"
        >
          {label}
        </label>
        {icon && <>{icon}</>}
      </div>
      {errors ? <InputError errorTitle={errorsText} /> : null}
    </div>
  );
};
export const CheckBox = ({
  className,
  idFrom,
  name,
  label,
  labelClass,
  ...res
}) => {
  return (
    <label
      htmlFor={idFrom}
      className={`flex items-center space-x-2 ${className}`}
    >
      <input
        type="checkbox"
        name={name}
        id={idFrom}
        className="w-4 h-4 peer accent-mainthemeBlack"
        {...res}
      />
      <p className={`md:text-sm text-xs font-sfPro ${labelClass}`}>{label}</p>
    </label>
  );
};
export const SelectBox = ({
  options,
  placeholder,
  errors,
  errorsText,
  className,
  paddingY,
  paddingX,
  ...res
}) => {
  return (
    <div>
      <ReactSelect
        className={`text-black p-0 responsive_base_text ${className}`}
        placeholder={placeholder}
        options={options}
        {...res}
        styles={{
          control: (base) => ({
            ...base,
            border: `1px solid ${errors ? "red" : "#0000003B"}`,
            borderRadius: "0.25rem",
            padding: `${paddingY ?? "6px"} ${paddingX ?? "4px"}`,
            boxShadow: "none",
            "&:hover": {
              border: "1px solid #0000003B",
            },
            option: (base, state) => ({
              ...base,
              backgroundColor: state.isSelected ? "#C71212" : "white",
              "&:active": {
                backgroundColor: state.isSelected
                  ? "#C71212"
                  : "rgba(0, 0, 0, 0.04)",
              },
            }),
          }),
        }}
      />
      {errors ? <InputError errorTitle={errorsText} /> : null}
    </div>
  );
};

export const ButtonTag = ({ className, value, type, ...rest }) => {
  return (
    <>
      <button
        // type={"type"}
        className={`font-semibold xl:text-base text-base transition duration-500 w-full py-2 lg:px-4 px-2 rounded-md bg-theme/20 ${className}`}
        {...rest}
      >
        {value}
      </button>
    </>
  );
};
export const AddPrimaryButton = ({
  className,
  value,
  height,
  width,
  type,
  ...res
}) => {
  return (
    <div className={`flex justify-center rounded ${className}`}>
      <button
        className={` text-white  flex items-center justify-center gap-2 px-6 py-2.5 rounded border border-themeBtn  ${className}`}
        type={type ?? "button"}
        {...res}
        style={{ height, width }}
      >
        <p>{value}</p>
        <ArrowNext className="fill-themeBtn mr-2" />
      </button>
    </div>
  );
};
export const DefaultButton = ({ className, type, value, ...res }) => {
  return (
    <div className={`flex justify-center py-2 ${className}`}>
      <button
        className="bg-mainthemeBlack text-white flex items-center justify-center px-6 py-2.5 rounded uppercase "
        type={type ?? "button"}
        {...res}
      >
        <p className="font-Proxima text-[15px]">{value}</p>
      </button>
    </div>
  );
};
export const AddCartButton = ({ className, type, value, ...res }) => {
  return (
    <div className={`flex py-2 ${className}`}>
      <button
        className="border border-lightBrown text-lightBrown flex items-center gap-2 justify-center px-8 py-2.5 rounded uppercase "
        type={type ?? "button"}
        {...res}
      >
        <AddCart />
        <p className="font-Proxima text-[15px]">{value}</p>
      </button>
    </div>
  );
};
export const RemoveButton = ({ className, type, value, ...res }) => {
  return (
    <div className={`flex py-2 ${className}`}>
      <button
        className="border border-shadedGrey text-shadedGrey flex items-center gap-2 justify-center px-8 py-2.5 rounded uppercase "
        type={type ?? "button"}
        {...res}
      >
        <Close />
        <p className="font-Proxima text-[15px]">{value}</p>
      </button>
    </div>
  );
};

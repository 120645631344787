import React, { useEffect, useState } from "react";
import {
  Decriment,
  Increment,
  UpArrow,
  ZoomOut,
} from "../../../assets/svg/AllSvg";
import FileViewPopup from "./FileViewPopup";
import { AddCartButton, RemoveButton } from "../../../components/micro";
import ConfirmationModel from "../../../common/ConfirmationModel";
import Cookies from "js-cookie";

const PackageDetails = ({
  pkg,
  counting,
  SetCounting,
  setIsNext,
  no_of_items,
  handlePackageSelection,
  handleRemovePackage,
  selectedPackages,
  isAdded,
}) => {
  const [isListVisible, setIsListVisible] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [count, setCount] = useState(0);
  const [isAddedToCart, setIsAddedToCart] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (no_of_items) {
      setCount(no_of_items);
    }

    // Check if the current package is already in the selectedPackages list
    if (Array.isArray(selectedPackages)) {
      const isPackageSelected = selectedPackages.find(
        (item) => item.id === pkg.id
      );
      if (isPackageSelected) {
        setIsAddedToCart(true);
        setCount(isPackageSelected.no_of_items || 1);
      } else {
        setIsAddedToCart(false);
      }
    }
  }, [selectedPackages, pkg.id, no_of_items]);

  const handleIncrement = () => {
    const newCount = count + 1;
    setIsNext(false);
    setCount(newCount);

    if (isAddedToCart) {
      const updatedPackages = counting.map((item) =>
        item.id === pkg.id ? { ...item, no_of_items: newCount } : item
      );
      SetCounting(updatedPackages);
    }
  };

  const handleDecrement = () => {
    if (count > 0) {
      const newCount = count - 1;
      setCount(newCount);

      if (isAddedToCart) {
        const updatedPackages = counting.map((item) =>
          item.id === pkg.id ? { ...item, no_of_items: newCount } : item
        );
        SetCounting(updatedPackages);

        if (newCount === 0) {
          setIsAddedToCart(false);
          SetCounting(counting.filter((item) => item.id !== pkg.id));
        }
      }
    }
  };

  // Add to Cart
  const handleAddToCart = () => {
    if (count === 0) {
      setCount(1);
    }
    setIsAddedToCart(true);
    setIsNext(false);
    handlePackageSelection({ ...pkg, no_of_items: count || 1 });
    SetCounting([{ ...counting, count: count || 1 }]);
  };

  const handleOpenConfirmation = () => {
    setConfirmDelete(true);
  };

  // Remove from Cart
  const handleRemoveFromCart = () => {
    setConfirmDelete(false);
    setIsAddedToCart(false);
    setCount(0);
    SetCounting([{ ...counting, count: 0 }]);

    // Remove package from cookies
    const updatedPackages = counting.filter((item) => item.id !== pkg.id); // Filter out the removed package
    Cookies.set("selectedPackages", JSON.stringify(updatedPackages)); // Save updated list to cookies

    handleRemovePackage(pkg.id); // Handle removing the package from the cart
  };

  // Handle zoom popup
  const handleZoomOutClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsPopupVisible(true);
    document.body.classList.add("no-scroll");
  };
  const handleClosePopup = () => {
    setIsPopupVisible(false);
    document.body.classList.remove("no-scroll");
  };

  return (
    <>
      <div
        className={`p-4 ${
          isAddedToCart ? "bg-lightGrey" : "bg-white"
        } transition-colors duration-300`}
      >
        <div className="sm:flex gap-5 ">
          {pkg?.images?.map((item, index) => (
            <div className="relative w-full h-52 sm:w-1/2  lg:h-44  md:h-36 rounded">
              {" "}
              <img
                className="object-cover w-full h-full cursor-pointer rounded"
                src={item?.document?.url}
                alt="Package"
                onClick={() => handleZoomOutClick(item?.document?.url)}
              />
              <div
                className="absolute top-2 right-0 xl:right-4 cursor-pointer"
                onClick={() => handleZoomOutClick(item?.document?.url)}
              >
                <ZoomOut />
              </div>
            </div>
          ))}
          <div className="w-full sm:mt-0 mt-2">
            <div className="uppercase sm:text-base text-xs text-black font-normal">
              {pkg?.name}
            </div>
            <p className="block mt-1 sm:text-lg text-sm font-normal text-black">
              ${pkg?.price}
            </p>
            <div>
              {pkg?.require_quantity === 1 && !isAdded ? (
                <div className="flex items-center mt-4">
                  <div className="cursor-pointer">
                    <button type="button" onClick={handleDecrement}>
                      <Decriment />
                    </button>
                  </div>
                  <span className="mx-4 sm:text-lg text-base">{count}</span>
                  <div className="cursor-pointer">
                    <button type="button" onClick={handleIncrement}>
                      <Increment />
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="mt-4">
              {!isAddedToCart && !isAdded ? (
                <AddCartButton value="Add To Cart" onClick={handleAddToCart} />
              ) : (
                <RemoveButton
                  value="Remove from Cart"
                  onClick={handleOpenConfirmation}
                />
              )}
            </div>
            <div className="mt-4 border-t border-t-grey border-b border-b-grey py-2">
              <div className="flex justify-between items-center">
                <span className="text-black">Description</span>
                <button onClick={() => setIsListVisible(!isListVisible)}>
                  <UpArrow
                    className={`transform transition-transform duration-300 ${
                      isListVisible ? "rotate-180" : "rotate-0"
                    }`}
                  />
                </button>
              </div>
              {isListVisible && (
                <>
                  <p className="sm:text-sm text-xs">{pkg?.description}</p>
                  <div className="mt-2 sm:text-base text-xs">
                    <p>Items:</p>
                    <ul>
                      {pkg?.items?.map((item, idx) => (
                        <li key={idx} className="list-disc ml-6">
                          {item?.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {isPopupVisible && (
          <FileViewPopup onClose={handleClosePopup} imageUrl={selectedImage} />
        )}
      </div>
      {confirmDelete && (
        <ConfirmationModel
          isLoading={false}
          confirmText={"Delete"}
          onClose={(e) => {
            setConfirmDelete(false);
          }}
          onConfirm={handleRemoveFromCart}
        />
      )}
    </>
  );
};

export default PackageDetails;

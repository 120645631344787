import React, { useState } from "react";
import { CheckCircle } from "../../../assets/svg/AllSvg";
import { Provider } from "../../../layouts/layoutContext";
import SelectPackege from "./SelectPackege";
import SelectDateTime from "./SelectDateTime";
import Contact from "./Contact";
import ViewQuatation from "./ViewQuatation";
import Cookies from "js-cookie";

const BookPackage = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [salesId, setSaleId] = useState("");
  const [selectedPackages, setSelectedPackages] = useState(() => {
    const storedPackages = Cookies.get("selectedPackages");
    return storedPackages ? JSON.parse(storedPackages) : [];
  });
  const [formvalue, setFormValue] = useState({
    performance_date_time: "",
    performance_address: "",
    performance_postal_code: "",
  });

  const handleRemovePackage = (packageId) => {
    const updatedPackages = selectedPackages.filter(
      (pkg) => pkg.id !== packageId
    );
    setSelectedPackages(updatedPackages);
    Cookies.set("selectedPackages", JSON.stringify(updatedPackages));
  };
  const tabLabel = [
    { value: "select_package", label: "Select Package" },
    { value: "date_time", label: "Select Date & Time" },
    { value: "contact_information", label: "Contact Information" },
    { value: "view_quotation", label: "View Quotation" },
  ];

  const renderStep = (step) => {
    switch (tabLabel[step]?.value) {
      case "select_package":
        return (
          <SelectPackege
            setSelectedPackages={setSelectedPackages}
            selectedPackages={selectedPackages}
            handleRemovePackage={handleRemovePackage}
          />
        );
      case "date_time":
        return (
          <SelectDateTime
            formvalue={formvalue}
            setFormValue={setFormValue}
            selectedPackages={selectedPackages}
            handleRemovePackage={handleRemovePackage}
          />
        );
      case "contact_information":
        return (
          <Contact
            formvalue={formvalue}
            selectedPackages={selectedPackages}
            setFormValue={setFormValue}
            setSaleId={setSaleId}
            salesId={salesId}
          />
        );
      case "view_quotation":
        return <ViewQuatation salesId={salesId} setFormValue={setFormValue} />;
      default:
        return null;
    }
  };

  const next = () => {
    if (currentStep < tabLabel.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };
  return (
    <>
      <div className="container mx-auto px-4">
        <Provider value={{ next, prev }}>
          <div className="bg-white my-2 p-4 rounded-2xl">
            <div className="stepper  flex sm:flex-row flex-col sm:justify-between sm:items-center sm:gap-0 sm:space-y-0 space-y-8 ">
              {tabLabel?.map((step, index) => (
                <div
                  key={index}
                  className="flex items-center gap-2 sm:text-left text-center"
                >
                  <span
                    className={`xl:max-w-8 max-w-6 xl:min-w-8 min-w-6 w-full xl:h-8 h-6 rounded-full flex justify-center items-center bg-meduimGrey text-white xl:text-base sm:text-sm text-xs ${
                      index < currentStep ? "!bg-mainthemeBlack " : ""
                    }${index === currentStep ? "!bg-mainthemeBlack" : ""}`}
                  >
                    {index < currentStep ? (
                      <CheckCircle circleFill="#fff" />
                    ) : (
                      index + 1
                    )}
                  </span>
                  <p
                    className={`xl:text-base  text-xs   text-black ${
                      index < currentStep ? "text-black" : ""
                    } ${index === currentStep ? "font-semibold" : ""}`}
                  >
                    {step.label}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div>{renderStep(currentStep)}</div>
        </Provider>
      </div>
    </>
  );
};

export default BookPackage;

import React, { useContext, useEffect, useState } from "react";
import { ArrowBack, ContactVector } from "../../../assets/svg/AllSvg";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import ReactSelect from "react-select";
import { InputError, InputWithLabel } from "../../../components/micro";
import layoutContext from "../../../layouts/layoutContext";
import SelectDetails from "./SelectDetails";
import { useDispatch, useSelector } from "react-redux";
import { getCountryData } from "../../../redux/address/slice";
import { createSales, getOneSales } from "../../../redux/sales/slice";
import RippleLoader from "../../../common/RippleLoader";

const payment = [
  { value: "cash", label: "Cash" },
  { value: "paynow", label: "Paynow" },
  { value: "bank_transfer", label: "Bank Transfer" },
  { value: "cheque", label: "Cheque" },
];

const Contact = ({
  formvalue,
  selectedPackages,
  setSaleId,
  salesId,
  setFormValue,
}) => {
  const { prev, next } = useContext(layoutContext);
  const [countryArray, setCountryArray] = useState([]);
  // const [formValues, setFormValues] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  //  Selector
  const { getSalesData, countryData, countryLoading } = useSelector(
    (store) => ({
      getSalesData: store?.salesData?.getOneSalesStatus?.data?.data,
      //Contry Data
      countryData: store?.addressData?.getCountryDataStatus?.data,
      countryLoading: store?.addressData?.getCountryDataStatus?.loading,
    })
  );
  useEffect(() => {
    if (salesId) {
      dispatch(getOneSales(salesId));
    }
  }, [salesId, dispatch]);
  // Get country
  useEffect(() => {
    if (!countryData?.length) {
      dispatch(getCountryData());
    }
  }, [dispatch]);

  useEffect(() => {
    if (countryData?.data) {
      let data = countryData?.data?.map((x) => ({
        ...x,
        label: `+${x?.id}`,
      }));
      setCountryArray(data);
    }
  }, [countryData]);
  const handleConfirm = (values) => {
    // return;

    setIsLoading(true);
    const packages = selectedPackages?.map((data) => ({
      ...data,
      package_id: data?.id,
    }));
    const payload = {
      name: values?.fullName,
      company_name: values?.company,
      email: values?.email,
      phone: values?.phone,
      phone_code: values?.phone_codeId,
      office_code: values?.office_codeId,
      office_number: values?.office_number,
      address: values?.address,
      postal_code: values?.postalcode,
      performance_date_time: formvalue?.performance_date_time,
      performance_address: formvalue?.performance_address,
      performance_postal_code: formvalue?.performance_postal_code,
      packages: selectedPackages?.length ? packages : [],
      deposit_payment_method: values?.paymentmethod,
    };
    dispatch(createSales(salesId ? { id: salesId, ...payload } : payload)).then(
      (res) => {
        if (res.type === "createSales/fulfilled") {
          setSaleId(res?.payload?.data?.id);
          next();
        }
        setIsLoading(false);
      }
    );
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        // initialValues={{
        //   fullName: getSalesData?.name || "",
        //   company: getSalesData?.company_name || "",
        //   email: getSalesData?.email || "",
        //   gender: getSalesData?.gender || "",
        //   dob: getSalesData?.dob || "",
        //   phone: getSalesData?.phone || "",
        //   postalcode: getSalesData?.postal_code || "",
        //   office_number: getSalesData?.office_number || "",
        //   phone_codeId: Number(getSalesData?.phone_code) || "",
        //   office_codeId: Number(getSalesData?.office_code) || "",
        //   country: getSalesData?.country || "",
        //   address: getSalesData?.address || "",
        //   paymentmethod: getSalesData?.deposit_payment_method || "",
        // }}
        initialValues={{
          fullName: formvalue?.fullName ?? (salesId ? getSalesData?.name : ""),
          company:
            formvalue?.company ?? (salesId ? getSalesData?.company_name : ""),
          email: formvalue?.email ?? (salesId ? getSalesData?.email : ""),
          phone: formvalue?.phone ?? (salesId ? getSalesData?.phone : ""),
          postalcode:
            formvalue?.postal_code ??
            (salesId ? getSalesData?.postal_code : ""),
          office_number:
            formvalue?.office_number ??
            (salesId ? getSalesData?.office_number : ""),
          address: formvalue?.address ?? (salesId ? getSalesData?.address : ""),
          paymentmethod:
            formvalue?.deposit_payment_method ??
            (salesId ? getSalesData?.deposit_payment_method : ""),
          phone_codeId: formvalue?.phone_code
            ? Number(formvalue?.phone_code)
            : salesId
            ? Number(getSalesData?.phone_code)
            : null,
          office_codeId: formvalue?.office_code
            ? Number(formvalue?.office_code)
            : salesId
            ? Number(getSalesData?.office_code)
            : null,
        }}
        validationSchema={Yup.object().shape({
          fullName: Yup.string().min(3).required("Please Enter Name"),
          company: Yup.string().required("company is required"),
          email: Yup.string()
            .email("Invalid email")
            .required("Email is required"),
          address: Yup.string().min(3).required("Please Enter Address"),
          phone_codeId: Yup.number().required("Please Select Country"),
          office_codeId: Yup.number().required("Please Select Country"),
          postalcode: Yup.string()
            .required("Please Enter Postal Code")
            .min(5, "Postal Code must be at least 5 characters")
            .matches(/^\d+$/, "Postal Code must be numeric"),
          phone: Yup.string()
            .required("please Enter Mobile")
            .min(8, "Mobile is too short - should be 8 chars minimum."),
          office_number: Yup.string()
            .required("please Enter Mobile")
            .min(8, "Mobile is too short - should be 8 chars minimum."),
        })}
        onSubmit={(values) => {
          handleConfirm(values);
          setFormValue((prevState) => ({ ...prevState, ...values }));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            {isLoading ? (
              <RippleLoader />
            ) : (
              <div className="grid sm:grid-cols-3 grid-rows items-start gap-2 my-3">
                <div className="bg-white rounded-2xl text-black py-5 px-6 sm:col-span-2">
                  <div className="cursor-pointer ">
                    <button
                      onClick={() => prev()}
                      className="flex gap-2 items-center"
                    >
                      <ArrowBack />
                      <p>Back</p>
                    </button>
                  </div>
                  <div className="flex items-center gap-3 mt-2">
                    <ContactVector />
                    <p className="text-brown md:text-2xl text-base font-normal">
                      Contact Information
                    </p>
                  </div>
                  <div>
                    <p className="sm:text-sm text-xs font-normal">
                      Let us know the billing information
                    </p>
                  </div>
                  <div className="pt-3">
                    <div className="flex flex-col gap-2 mb-4">
                      <div className="mb-3">
                        {/* Name */}
                        <InputWithLabel
                          className={`${"text-black border "}  ${
                            errors.fullName && touched.fullName
                              ? `border `
                              : `border  `
                          }  transition duration-300 ease-in-out`}
                          idFromName="fullName"
                          label="Name*"
                          type="text"
                          onChange={(e) => {
                            handleChange(e);
                            setFormValue((prevState) => ({
                              ...prevState,
                              fullName: e.target.value,
                            }));
                          }}
                          onBlur={handleBlur}
                          value={values.fullName}
                          errors={errors.fullName && touched.fullName}
                          errorsText={errors.fullName}
                        />
                      </div>
                      {/* compnay name */}
                      <div>
                        <InputWithLabel
                          className={`${"text-black border "}  ${
                            errors.company && touched.company
                              ? `border `
                              : `border  `
                          }  transition duration-300 ease-in-out`}
                          idFromName="company"
                          label="Company Name"
                          type="text"
                          onChange={(e) => {
                            handleChange(e); // Update Formik's state
                            setFormValue((prev) => ({
                              ...prev,
                              company: e.target.value, // Update external state
                            }));
                          }}
                          onBlur={handleBlur}
                          value={values.company}
                          errors={errors.company && touched.company}
                          errorsText={errors.company}
                        />
                      </div>
                      <div className="grid lg:grid-cols-2 gap-4 mt-2">
                        <div className="grid grid-cols-3 gap-2">
                          {/* Country */}
                          <div>
                            <div
                              className={`  ${
                                errors.country && touched.country
                                  ? `  border`
                                  : ` border-borderTheme/80`
                              } relative bg-white transition duration-300 rounded plus-number-inputs`}
                            >
                              <ReactSelect
                                id="office_codeId"
                                placeholder="Country"
                                isLoading={countryLoading}
                                options={countryArray}
                                getOptionLabel={(countryArray) =>
                                  countryArray.label
                                }
                                getOptionValue={(countryArray) =>
                                  countryArray.id
                                }
                                value={
                                  countryArray?.length > 0 &&
                                  countryArray?.find((op) => {
                                    return (
                                      op?.phonecode === values?.office_codeId
                                    );
                                  })
                                }
                                classNamePrefix="react-select"
                                onChange={(e) => {
                                  setFieldValue(
                                    "office_codeId",
                                    Number(e?.phonecode)
                                  );
                                }}
                                styles={{
                                  control: (base) => ({
                                    ...base,
                                    border: `1px solid #0000003B`,
                                    borderColor: "#0000003B",
                                    borderRadius: "0.3rem",
                                    paddingTop: "2px",
                                    paddingBottom: "2px",
                                    boxShadow: "none",
                                    "&:hover": {
                                      border: "1px solid #0000003B",
                                    },
                                  }),
                                  option: (base, state) => ({
                                    ...base,
                                    backgroundColor: state.isSelected
                                      ? "#8F6500"
                                      : "white",
                                    "&:active": {
                                      backgroundColor: state.isSelected
                                        ? "#8F6500"
                                        : "rgba(0, 0, 0, 0.04)",
                                    },
                                  }),
                                }}
                              />
                              <label
                                htmlFor="country"
                                className="absolute text-primaryblack pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2  origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                              >
                                Country
                              </label>
                            </div>
                            {errors.office_codeId && touched.office_codeId ? (
                              <InputError errorTitle={errors.office_codeId} />
                            ) : null}
                          </div>
                          {/*office Phone */}
                          <div className="col-span-2">
                            <InputWithLabel
                              className={`${"text-black border "}  ${
                                errors.office_number && touched.office_number
                                  ? `border `
                                  : `border `
                              }  transition duration-300 ease-in-out`}
                              idFromName="office_number"
                              label="Office Number"
                              type="numeric"
                              onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*$/.test(value)) {
                                  handleChange(e);
                                }
                              }}
                              onBlur={handleBlur}
                              value={values.office_number}
                              errors={
                                errors.office_number && touched.office_number
                              }
                              errorsText={errors.office_number}
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-3 gap-2">
                          {/* Country */}
                          <div>
                            <div
                              className={`  ${
                                errors.country && touched.country
                                  ? `  border`
                                  : ` border-borderTheme/80`
                              } relative bg-white transition duration-300 rounded plus-number-inputs`}
                            >
                              <ReactSelect
                                id="phone_codeId"
                                placeholder="Country"
                                isLoading={countryLoading}
                                options={countryArray}
                                getOptionLabel={(countryArray) =>
                                  countryArray.label
                                }
                                getOptionValue={(countryArray) =>
                                  countryArray.id
                                }
                                value={
                                  countryArray?.length > 0 &&
                                  countryArray?.find((op) => {
                                    return (
                                      op?.phonecode === values?.phone_codeId
                                    );
                                  })
                                }
                                classNamePrefix="react-select"
                                onChange={(e) => {
                                  setFieldValue(
                                    "phone_codeId",
                                    Number(e?.phonecode)
                                  );
                                }}
                                styles={{
                                  control: (base) => ({
                                    ...base,
                                    border: `1px solid #0000003B`,
                                    borderColor: "#0000003B",
                                    borderRadius: "0.3rem",
                                    paddingTop: "2px",
                                    paddingBottom: "2px",
                                    boxShadow: "none",
                                    "&:hover": {
                                      border: "1px solid #0000003B",
                                    },
                                  }),
                                  option: (base, state) => ({
                                    ...base,
                                    backgroundColor: state.isSelected
                                      ? "#8F6500"
                                      : "white",
                                    "&:active": {
                                      backgroundColor: state.isSelected
                                        ? "#8F6500"
                                        : "rgba(0, 0, 0, 0.04)",
                                    },
                                  }),
                                }}
                              />
                              <label
                                htmlFor="country"
                                className="absolute text-primaryblack pointer-events-none text-sm sm:text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2  origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                              >
                                Country
                              </label>
                            </div>
                            {errors.phone_codeId && touched.phone_codeId ? (
                              <InputError errorTitle={errors.phone_codeId} />
                            ) : null}
                          </div>
                          {/* Phone */}
                          <div className="col-span-2">
                            <InputWithLabel
                              className={`${"text-black border "}  ${
                                errors.phone && touched.phone
                                  ? `border `
                                  : `border  `
                              }  transition duration-300 ease-in-out`}
                              idFromName="phone"
                              label="Mobile"
                              type="numeric"
                              onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*$/.test(value)) {
                                  handleChange(e);
                                }
                              }}
                              onBlur={handleBlur}
                              value={values.phone}
                              errors={errors.phone && touched.phone}
                              errorsText={errors.phone}
                            />
                          </div>
                        </div>
                      </div>

                      {/* Email */}
                      <div className="mb-3">
                        <InputWithLabel
                          className={`${"text-black border "}  ${
                            errors.email && touched.email
                              ? `border `
                              : `border  `
                          }  transition duration-300 ease-in-out`}
                          idFromName="email"
                          label="Email*"
                          type="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          errors={errors.email && touched.email}
                          errorsText={errors.email}
                        />
                      </div>

                      <div className="grid sm:grid-cols-2 gap-3">
                        {/* Address */}
                        <InputWithLabel
                          className={` ${
                            errors.address && touched.address
                              ? `border `
                              : `border  `
                          } transition duration-300 ease-in-out`}
                          idFromName="address"
                          label="Address*"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.address}
                          errors={errors.address && touched.address}
                          errorsText={errors.address}
                        />
                        {/* Postal Code */}
                        <InputWithLabel
                          className={` ${
                            errors.postalcode && touched.postalcode
                              ? `border `
                              : `border  `
                          } transition duration-300 ease-in-out`}
                          idFromName="postalcode"
                          label="Postal Code*"
                          type="numeric"
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d*$/.test(value)) {
                              handleChange(e);
                            }
                          }}
                          onBlur={handleBlur}
                          value={values.postalcode}
                          errors={errors.postalcode && touched.postalcode}
                          errorsText={errors.postalcode}
                        />
                      </div>

                      <div className="">
                        <div
                          className={` relative bg-white transition duration-300 rounded plus-number-inputs`}
                        >
                          <ReactSelect
                            className={"w-full  text-black p-0 font-Proxima"}
                            id="role"
                            placeholder="Deposit Payment Method"
                            getOptionValue={(payment) => payment?.value}
                            getOptionLabel={(payment) => payment?.label}
                            value={
                              payment.find(
                                (option) =>
                                  option.value === values.paymentmethod
                              ) || null
                            }
                            onChange={(selectedOption) => {
                              setFieldValue(
                                "paymentmethod",
                                selectedOption?.value
                              );
                            }}
                            options={payment}
                            styles={{
                              control: (base) => ({
                                ...base,
                                border: `1px solid #0000003B`,
                                borderColor: "#0000003B",
                                borderRadius: "0.3rem",
                                paddingTop: "2px",
                                paddingBottom: "2px",
                                boxShadow: "none",
                                "&:hover": {
                                  border: "1px solid #0000003B",
                                },
                              }),
                              option: (base, state) => ({
                                ...base,
                                backgroundColor: state.isSelected
                                  ? "#8F6500"
                                  : "white",
                                "&:active": {
                                  backgroundColor: state.isSelected
                                    ? "#8F6500"
                                    : "rgba(0, 0, 0, 0.04)",
                                },
                              }),
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <SelectDetails
                  next={next}
                  isContact={true}
                  selectedPackages={selectedPackages}
                />
              </div>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Contact;

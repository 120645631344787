import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AxiosApi } from "../../helpers/AxiosApi";
import initialStates from "./state";

// Get cart List
export const getCartList = createAsyncThunk(
  "getCartList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`cart`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
// Create Edit sales
export const addtocart = createAsyncThunk(
  "addtocart",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`add-to-cart`, data);
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete cart
export const deleteCart = createAsyncThunk(
  "deleteCart",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.delete(`/cart/${id}`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

const cartSlice = createSlice({
  name: "cart",
  initialState: initialStates,
  extraReducers: (builder) => {
    builder
      // get cart List
      .addCase(getCartList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCartList.fulfilled, (state, action) => {
        state.loading = false;
        state.getCartListStatus.data = action.payload;
        state.error = false;
      })
      .addCase(getCartList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Create Edit cart
      .addCase(addtocart.pending, (state) => {
        state.loading = true;
      })
      .addCase(addtocart.fulfilled, (state, action) => {
        state.loading = false;
        state.addtocartStatus.data = action.payload;
        state.error = false;
      })
      .addCase(addtocart.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      // Delete cart
      .addCase(deleteCart.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCart.fulfilled, (state, action) => {
        state.loading = false;
        state.deleteCartStatus.data = action.payload;
        state.error = false;
      })
      .addCase(deleteCart.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});
const { reducer } = cartSlice;
export default reducer;

import React from "react";
import BookPackage from "./package/BookPackage";

const Home = () => {
  return (
    <>
      <div className={`overflow-auto relative`}>
        <div>
          <div className="container mx-auto px-4 py-8">
            <p className="lg:text-[96px] sm:text-6xl text-2xl text-white font-light text-start pt-8 ">
              Booking Details
            </p>
            <p className="sm:text-base text-sm text-white font-normal mt-2">
              Select your package, choose a date, and provide your details to
              confirm your booking
            </p>
          </div>
        </div>
        <div className="text-white">
          <BookPackage />
        </div>
      </div>
    </>
  );
};

export default Home;

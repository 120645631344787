import React from "react";
import { Logo } from "../assets/images";

const Header = () => {
  return (
    <>
      <header className="sticky top-0 left-0 w-full z-[9]">
        <div className="shadow-md bg-mainthemeBlack p w-full p-3">
          <div className="flex gap-2 cursor-pointer justify-center">
            <span>
              <div className="text-white ">
                <img src={Logo} className="h-16" />
              </div>
            </span>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;

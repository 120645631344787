import React from "react";
import { Upload } from "../../../assets/images";
import { FillColse } from "../../../assets/svg/AllSvg";

const FileViewPopup = ({ onClose, imageUrl }) => {
  return (
    <>
      <div className="model">
        <div className="relative p-5 w-auto h-auto max-w-[70%] sm:max-w-[30rem] max-h-[35%] sm:max-h-[30rem]">
          <div className="w-full h-full">
            <img
              src={imageUrl || Upload}
              alt=""
              className="w-full h-full object-contain rounded-lg"
            />
            {/* Close button */}
            <button onClick={onClose} className="absolute top-2 right-2">
              <FillColse />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FileViewPopup;

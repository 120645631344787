import { configureStore } from "@reduxjs/toolkit";
import PackagesSlice from "./package/slice";
import salesSlice from "./sales/slice";
import addressSlice from "./address/slice";
import cartSlice from "./cart/slice";

const rootReducer = {
  packageData: PackagesSlice,
  salesData: salesSlice,
  addressData: addressSlice,
  cartData: cartSlice,
};

const store = configureStore({
  reducer: rootReducer,
});

export default store;

import axios from "axios";
const baseURL = process.env.REACT_APP_BASEURL;
const supportBoardBaseURL = process.env.SUPPORT_BOARD_URL;

export const AxiosApi = axios.create({
  baseURL,
});
export const supportBoardApi = axios.create({
  supportBoardBaseURL,
});

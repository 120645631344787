import { data } from "react-router-dom";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  loading: false,
  error: false,
  addtocartStatus: { data: null },
  deleteCartStatus: { data: null },
  getCartListStatus: { data: null },
};

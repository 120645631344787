import React, { useContext, useEffect } from "react";
import { ArrowBack } from "../../../assets/svg/AllSvg";
import { Logo, Sign } from "../../../assets/images";
import { DefaultButton } from "../../../components/micro";
import { useNavigate } from "react-router-dom";
import layoutContext from "../../../layouts/layoutContext";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getOneSales } from "../../../redux/sales/slice";
import RippleLoader from "../../../common/RippleLoader";

const ViewQuatation = ({ salesId, setFormValue }) => {
  const { prev } = useContext(layoutContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //  Selector
  const { getSalesData, loading } = useSelector((store) => ({
    getSalesData: store?.salesData?.getOneSalesStatus?.data?.data,
    loading: store?.salesData?.loading,
  }));

  const totalAmount = getSalesData?.packages
    ?.reduce(
      (sum, pkg) => sum + pkg?.package?.price * (pkg.no_of_items || 1),
      0
    )
    .toFixed(2);

  useEffect(() => {
    if (salesId) {
      dispatch(getOneSales(salesId));
    }
  }, [salesId, dispatch]);
  return (
    <>
      <div className="bg-white rounded-2xl text-black py-5 sm:px-6  col-span-2 space-y-2 my-3">
        <div className="cursor-pointer sm:px-0 px-2">
          <button
            onClick={() => prev(salesId)}
            className="flex gap-2 items-center"
          >
            <ArrowBack />
            <p>Back</p>
          </button>
        </div>
        {loading ? (
          <RippleLoader />
        ) : (
          <div className="shadow md:p-10 p-2 mt-2">
            <div className="grid lg:grid-cols-4">
              <div className="col-span-3">
                <img src={Logo} alt="" className="w-1/4" />
              </div>
              <div className="flex flex-col gap-2">
                <p className="sm:text-xl text-base font-normal font-poppins text-brown">
                  {" "}
                  Yi Wei Athletic Association{" "}
                </p>
                <div className="sm:text-sm text-xs font-normal text-secondary_black">
                  <p>Blk 446 Hougang ave 8 #B1-1635 Singapore 530446 </p>
                  <p>Tel:6489 5766 Fax:6487 6276 Hp:9272 0272 </p>
                  <p>Website: www.yiwei.com.sg/ </p>
                  <p>Email: enquiry@yiwei.com.sg</p>
                </div>
              </div>
            </div>
            <div className="my-2">
              <p className="sm:text-2xl text-xl font-normal">Quotation</p>
            </div>
            <div className="flex justify-between">
              <div className="sm:text-sm text-xs font-normal txet-secondary_black">
                <p>Quotation ID: {getSalesData?.quotation_id}</p>
                <p>
                  Date:{" "}
                  {getSalesData?.created_at
                    ? moment(getSalesData.created_at).format("DD/MM/YYYY")
                    : ""}
                </p>
              </div>
              <div>
                <p className="sm:text-sm text-xs font-normal txet-secondary_black text-end">
                  Total Amount:
                </p>
                <span className="lg:text-4xl sm:2xl text-base text-brown font-normal">
                  ${totalAmount}
                </span>
              </div>
            </div>
            <div className="mt-4 grid md:grid-cols-2  justify-between">
              <div class="sm:text-sm text-xs max-w-[256px]">
                <p>
                  <span class=" inline-block w-20">Attn To:</span>
                  {getSalesData?.name}
                </p>
                <p>
                  <span class=" inline-block w-20">Tel:</span>
                  {getSalesData?.phone}
                </p>
                <p>
                  <span class=" inline-block w-20">Email:</span>
                  {getSalesData?.email}
                </p>
                <p>
                  <span class=" inline-block w-20">Address:</span>
                  {getSalesData?.address} {getSalesData?.postal_code}
                </p>
              </div>
              <div className="md:mt-0 mt-2">
                <div className="bg-lightPrimaryGrey p-4 rounded-md grid grid-cols-2 justify-between">
                  <div className="sm:text-sm text-xs">
                    <p className="text-sm text-primaryblack">
                      Performance Date & Time:
                    </p>
                    <p>
                      {" "}
                      {getSalesData?.performance_date_time
                        ? moment(getSalesData.performance_date_time).format(
                            "DD/MM/YYYY h:mm a"
                          )
                        : ""}
                    </p>
                  </div>
                  <div className="sm:text-sm text-xs">
                    <p className="text-sm text-primaryblack">
                      Performance Location:
                    </p>
                    <p>
                      {" "}
                      {getSalesData?.performance_address}{" "}
                      {getSalesData?.performance_postal_code}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {/* table */}
              <table className="table w-full mt-5 ">
                <thead>
                  <tr className="text-left  bg-lightGrey ">
                    <th className="p-4  sm:text-sm text-xs font-medium">S/N</th>
                    <th className="p-4  sm:text-sm text-xs font-medium">
                      Item & Package
                    </th>
                    <th className="p-4 sm:text-sm text-xs font-medium">
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {getSalesData?.packages?.map((item, index) => (
                    <tr key={index} className="border-b overflow-auto">
                      <td className="p-4 ">
                        {String.fromCharCode(65 + index)}
                      </td>
                      <td className="p-4 flex flex-col gap-3">
                        <p className="text-sm lg:text-base  font-normal ">
                          {item?.package?.name}
                        </p>
                        {item?.description && item.description.trim() !== "" ? (
                          <>
                            <p>
                              Golden Package {String.fromCharCode(65 + index)}{" "}
                              Includes:
                            </p>
                            <ul className=" ml-5">
                              <li className="sm:text-sm lg:text-base text-xs font-normal">
                                {item?.description}
                              </li>
                            </ul>
                          </>
                        ) : null}
                        {item.package?.items?.length > 0 ? (
                          <>
                            <ul className="list-disc ml-5">
                              {item.package.items.map((packageItem) => (
                                <li
                                  key={packageItem.id}
                                  className="sm:text-sm lg:text-base text-xs font-normal"
                                >
                                  {packageItem.name}
                                </li>
                              ))}
                            </ul>
                          </>
                        ) : null}
                      </td>
                      <td className="p-2 sm:text-base text-sm">
                        $
                        {(
                          item?.package?.price * (item?.no_of_items || 1)
                        ).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* terms condition */}
            <div className="mt-6 sm:flex items-start justify-between">
              <div className="text-xs">
                <p>TERMS AND CONDITIONS:</p>
                <p>
                  *30 days credit payment terms upon completion of performance.
                </p>
                <p>
                  *50% of agreed price will be charged upon cancellation after
                  confimation.
                </p>
                <p>
                  *In the event of performance cancellation due to wet
                  weather,no refunds or discounts will be provided.
                </p>
                <p>
                  *In the event of performance stunt mistakes,no refunds or
                  discounts will be provided.
                </p>
                <p>
                  *Quotation is only valid for X07 days subjected to our
                  schedule availability.
                </p>
                <p>Thank you.</p>
              </div>
              <div className="text-sm font-normal text-#000000 ">
                <p>Subtotal: {totalAmount}</p>
                <p>Total: {totalAmount}</p>
              </div>
            </div>
            {/* Signature */}
            <div className="sm:flex justify-between mt-5">
              <div className="sm:w-1/4 w-44">
                <div>
                  <p className="text-primaryblack">Yours Truly,</p>
                </div>
                <div className="p-4 ">
                  <img src={Sign} alt="" />
                </div>
                <div className="border-t text-sm text-primaryblack">
                  <p>Marcus Tock</p>
                  <p>Yiwei Athletic Association </p>
                  <p>Hp: 9999 222</p>
                </div>
              </div>
              <div className="sm:w-1/4 w-44 sm:mt-0 mt-3">
                <div>
                  <p className="text-primaryblack">Confirmed by,</p>
                </div>
                <div className="p-12"></div>
                <div className="border-t text-sm text-primaryblack">
                  <p>Signature & Date</p>
                  <p>Name: </p>
                  <p>Designation</p>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="flex justify-end mr-2">
          <DefaultButton
            value="Accept"
            onClick={() => {
              setFormValue("");
              const cookies = document.cookie.split("; ");
              for (let cookie of cookies) {
                const [name] = cookie.split("=");
                document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
              }
              setFormValue("");
              navigate("/message", { state: { salesId } });
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ViewQuatation;
